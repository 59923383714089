<template>
  <div class="h-screen bg-gray-150 flex flex-col justify-center">
    <div class="flex flex-col items-center">
      <div class=" bg-white rounded p-6" style="width: 300px">
        <div class="flex justify-center mt-3 mb-6">
          <img src="../assets/logo.png" class="w-32" />
        </div>
        <cube-input v-model="username" placeholder="用户名" class="mb-4"></cube-input>
        <cube-input v-model="password" placeholder="密码" class="mb-4"></cube-input>
        <div class="mb-4 flex items-center">
          <cube-input v-model="code" class="flex-1" placeholder="验证码"></cube-input>
          <!-- <btn size="md" class="ml-2" outline>获取验证码</btn> -->
          <media class="ml-2" width="90" height="40" ratio="none" src="http://pics.sc.chinaz.com/files/pic/pic9/201712/bpic4933.jpg"></media>
        </div>
        <btn block text-size="base" @click="login">登录</btn>
        <div class="flex justify-between mt-4">
          <btn size="md" outline color="orange-400" url="/register/1">商户注册</btn>
          <btn size="md" outline url="/register/2">推广员注册</btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState,mapGetters, mapActions} from 'vuex'
export default {
  data(){
    return{
      username: '',
      password: '',
      code: '',
    }
  },
  computed: {
    ...mapGetters([
      'TestData'
    ]),
    ...mapState([
      'loggedIn',
    ]),
  },
  methods:{
    ...mapActions([
      'aLogin'
    ]),
    login(){
      this.aLogin(true);
      this.$router.push('/');
    }
  },
  created(){}
}
</script>

<style lang='stylus'>

</style>